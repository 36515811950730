import React from "react"
import { Link, graphql } from "gatsby"
import VisitButton from "../components/buttons/VisitButton"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo"
import backgroundHero from '../images/index/sf-banner.jpg';
import '../stylesheets/index.scss';
import '../stylesheets/style.scss';


const IndexPage = ({ data }) => {
  const pageDetails = data.allContentfulPageHeader.edges;
  const headerTitle = data.contentfulPageHeader.title;
  const headerDesc = data.contentfulPageHeader.body.body;
  const headerImage = data.contentfulPageHeader.heroImage.fluid.src;


  return (
    <Layout>
      <SEO title={headerTitle} pageDescription={headerDesc} heroImage={headerImage} keywords={[`gatsby`, `application`, `react`]} />
      <div className="container-fluid">
        <div className="row">
          <div className="banner">
            <img src={backgroundHero} alt="home page" className="img-fluid" />
            <div className="banner_text">
              <h1>Justin Abercrombia</h1>
            </div>
          </div>
          <div className="container-fluid portfolioSection text-center">
            <div className="container">
              <div className="row">
                <div className="col">
                  <Logo />
                  <h2>PORTFOLIO</h2>
                  <p>
                    A Jack of all trades ranging from web development to designer. I am self taught in a lof of things and always eager to learn new stuff.
                  </p>
                </div>
              </div>
            </div>
          </div>


          {pageDetails.map(({ node }, index) => (
            <div className="container-fluid portfolioItems" data-name={node.id}>
              <div className="container">
                <div className="row">
                  <div key={node} className="col-12 col-md-6">
                  <Link to={'/' + node.title}><img src={node.heroImage.fixed.src} alt={node.title} className="img-fluid" /></Link>
                  </div>
                  <div className={index % 2 !== 0 ? 'col-12 col-md-6 align-self-center text-right' : 'col-12 col-md-6 align-self-center order-md-first text-left'}>
                    <h2 className={index % 2 !== 0 ? 'text-right' : 'text-left'}>{node.title}</h2>
                    <div className="body-text"
                      dangerouslySetInnerHTML={{
                        __html: node.body.childMarkdownRemark.html,
                      }}
                    ></div>
                    <Link to={'/' + node.title}>
                      <div className={index % 2 === 0 ? 'float-left' : 'float-right'}>
                        <VisitButton text="visit" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}


        </div>
      </div>

    </Layout >
  )
}

export default IndexPage

export const query = graphql`
{
  allContentfulContentType(filter: {name: {nin: ["Page Header","Technology","Blogpost Category","Blog Post","Site Information","About Me"]}}) {
    edges {
      node {
        name
        description
      }
    }
  }
  allContentfulPageHeader(filter: {title: {nin: ["justin abercrombia", "home"]}}, sort: {order: DESC, fields: title}) {
    edges {
      node {
        title
        heroImage {
          fixed(height: 400, width: 600, quality:100) {
            src
          }
        }
        body {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
  contentfulPageHeader(title: {eq: "home"}) {
    title
    body{
      body
    }
    heroImage {
      file {
        url
      }
      fluid(maxWidth: 2000, quality: 100) {
        src
      }
    }
  }
}
`